/* eslint-disable react-hooks/exhaustive-deps */
'use client'

import useSWR from 'swr'

import { getAuthUser } from '../api'
import { AUTH_USER_REVALIDATE_KEY } from '../config'

export const useAuthUser = () => {
  const { data, mutate, isLoading, isValidating } = useSWR([AUTH_USER_REVALIDATE_KEY], () =>
    getAuthUser(),
  )

  return {
    data,
    mutate,
    isAuth: !!data,
    isLoading,
    isValidating,
    isLoadingOrValidating: isLoading || isValidating,
  }
}
